/* Apply class when document has been scrolled */
/* Used to e.g. add a shadow to the header when the page is scrolled */

document.addEventListener('DOMContentLoaded', () => {
	let ticking = false;

	window.addEventListener('scroll', () => {
		if (!ticking) {
			requestAnimationFrame(() => {
				document.documentElement.classList.toggle('is-scrolled', window.scrollY > 0);
				ticking = false;
			});
			ticking = true;
		}
	}, { passive: true });

	window.dispatchEvent(new Event('scroll'));
});
